import { useEffect } from "react";
import OktaAuth from "@okta/okta-auth-js";

const authClient = new OktaAuth({
  url: process.env.REACT_APP_OKTA_BASE_URL,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  autoRenew: true,
  redirectUri: process.env.REACT_APP_OKTA_REDIRECT_URI
});

export const handleLogin = (onLogin: () => void) => {
  authClient.tokenManager.get("accessToken").then((tokens: any) => {
    const windowHash = window.location.hash;
    if (tokens) {
      onLogin();
    } else if (windowHash) {
      authClient.token.parseFromUrl().then((token: any[]) => {
        token.forEach(t => {
          if (t.idToken) {
            authClient.tokenManager.add("idToken", t);
          }
          if (t.accessToken) {
            authClient.tokenManager.add("accessToken", t);
          }
        });
        onLogin();
      });
    } else {
      authClient.token.getWithRedirect({
        responseType: ["token", "id_token"],
        scopes: ["openid", "profile", "email", "offline_access"],
        nonce: "uSeRInfo",
        expireEarlySeconds: 120
      });
    }
  });
  authClient.tokenManager.get("idToken");
};

export const setupRefresh = (authClient: {
  tokenManager: { on: (arg0: string, arg1: () => void) => void; get: (arg0: string) => void };
}) => {
  authClient.tokenManager.on("expired", () => {
    authClient.tokenManager.get("accessToken");
    authClient.tokenManager.get("idToken");
  });
};

export const useOkta = (config: { url: any; clientId: any; issuer: any; redirectUri: any }, onLogin: { (): void }) => {
  useEffect(() => {
    if (config) {
      setupRefresh(authClient);
      handleLogin(onLogin);
    }
  }, [config]);
};
